import { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Settings, User, LogOut, UserCog, Search } from 'lucide-react';
import ChatList from './ChatList';
import ArtistModal from './ArtistModal';
import styles from './styles/css/LeftSidebar.module.css';
import { cssVariables } from './styles/theme';

const UserMenu = ({ isVisible, onClose, userName }: any) => {
  if (!isVisible) return null;

  return (
    <div className={styles.userMenu} onClick={(e) => e.stopPropagation()}>
      <div className={styles.userMenuHeader}>
        <div className={styles.userMenuText}>
          Signed in as <strong>{userName}</strong>
        </div>
      </div>
      <div className={styles.menuDivider}>
        <button className={styles.userMenuItem}>
          <UserCog className="w-4 h-4" />
          <span>Account settings</span>
        </button>
        <button className={styles.userMenuItem}>
          <Settings className="w-4 h-4" />
          <span>Preferences</span>
        </button>
      </div>
      <div className={styles.menuDivider}>
        <button className={`${styles.userMenuItem} ${styles.danger}`}>
          <LogOut className="w-4 h-4" />
          <span>Log out</span>
        </button>
      </div>
    </div>
  );
};

const LeftSidebar = ({ onSelectChat, currentChatId, userImage, userName }: any) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isArtistModalOpen, setIsArtistModalOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const refreshChatsRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleArtistSelect = async (chatData: any) => {
    if (refreshChatsRef.current) {
      // @ts-ignore
      await refreshChatsRef.current();
    }
    onSelectChat(chatData.threadId);
    setIsArtistModalOpen(false);
  };

  return (
    <div 
      className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : styles.expanded}`}
      style={cssVariables}
    >
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={styles.collapseButton}
      >
        {isCollapsed ? (
          <ChevronRight className="w-4 h-4" />
        ) : (
          <ChevronLeft className="w-4 h-4" />
        )}
      </button>

      <div className={styles.header}>
        {isCollapsed ? (
          <h2 className={`${styles.title} ${styles.collapsed}`}>HC</h2>
        ) : (
          <img
            src="assets/hitcraft-logo@3x.png"
            alt="HitCraft Logo"
            className="hitcraft_logo"
          />
        )}
      </div>

      <div className={styles.actionSection}>
        <button
          onClick={() => setIsArtistModalOpen(true)}
          className={`${styles.newChatButton} ${isCollapsed ? styles.collapsed : ''}`}
        >
          {isCollapsed ? '+' : 'New Chat'}
        </button>

        {!isCollapsed && (
          <div className={styles.searchContainer}>
            <Search className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search musicians..."
              className={styles.searchInput}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        )}
      </div>

      <div className={styles.chatListContainer}>
        <ChatList
          onSelectChat={onSelectChat}
          currentChatId={currentChatId}
          onChatUpdate={(refreshFunction: any) => {
            refreshChatsRef.current = refreshFunction;
          }}
          // @ts-ignore
          searchQuery={searchQuery}
          isCollapsed={isCollapsed}
        />
      </div>

      <div className={styles.userSection} ref={menuRef}>
        <button
          onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
          className={styles.userButton}
        >
          {userImage ? (
            <img src={userImage} alt={userName} className={styles.userAvatar} />
          ) : (
            <div className={styles.userAvatarFallback}>
              <User className="w-5 h-5" />
            </div>
          )}
          {!isCollapsed && (
            <>
              {/* <span className={styles.userName}>{userName}</span> */}
              <span className={styles.userName}>Idan</span>
              <Settings className={styles.settingsIcon} />
            </>
          )}
        </button>

        <UserMenu 
          isVisible={isUserMenuOpen}
          onClose={() => setIsUserMenuOpen(false)}
          userName={userName}
        />
      </div>

      <ArtistModal
        isOpen={isArtistModalOpen}
        onClose={() => setIsArtistModalOpen(false)}
        onSelectArtist={handleArtistSelect}
      />
    </div>
  );
};

export default LeftSidebar;