import { useState, useEffect } from 'react';
import { API, API_BASE_URL, TOKEN } from './config';
import { Trash2, Mic } from 'lucide-react';
import styles from './styles/css/ChatList.module.css';

const ChatList = ({ onSelectChat, currentChatId, onChatUpdate }: any) => {
  interface Thread {
    artistId: string;
    title: string;
  }
  
  const [threads, setThreads] = useState<{ [key: string]: Thread }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(null);

  const fetchChats = async () => {
    console.log('Fetching chats...');
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/${API.chat.list}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch chats');
      const data = await response.json();
      console.log('Fetched chats:', data);
      setThreads(data.threads);
    } catch (err) {
      console.error('Error fetching chats:', err);
      setError('Failed to load chats');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onChatUpdate(fetchChats);
    fetchChats(); // Initial fetch
  }, []);

  const handleDeleteChat = async (event: any, chatId: any) => {
    event.stopPropagation();
    if (window.confirm('Are you sure you want to delete this chat?')) {
      setIsDeleting(chatId);
      try {
        const response = await fetch(`${API_BASE_URL}/${API.chat.delete}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ threadId: chatId }),
        });
        if (!response.ok) throw new Error('Failed to delete chat');
        setThreads((prevThreads) => {
          const updatedThreads = { ...prevThreads };
          // @ts-ignore
          delete updatedThreads[chatId];
          return updatedThreads;
        });
        if (currentChatId === chatId) {
          onSelectChat(null);
        }
      } catch (err) {
        console.error('Error deleting chat:', err);
        alert('Failed to delete chat. Please try again.');
      } finally {
        setIsDeleting(null);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.chatList}>
        {isLoading ? (
          <div className={`${styles.statusMessage} ${styles.hideWhenCollapsed}`}>
            Loading chats...
          </div>
        ) : error ? (
          <div className={`${styles.errorMessage} ${styles.hideWhenCollapsed}`}>
            {error}
          </div>
        ) : Object.keys(threads).length === 0 ? (
          <div className={`${styles.statusMessage} ${styles.hideWhenCollapsed}`}>
            No chats yet
          </div>
        ) : (
          <div className={styles.chatButtonsList}>
            {Object.entries(threads).map(([id, thread]) => (
              <button
                key={id}
                onClick={() => onSelectChat({
                  id,
                  artistId: thread.artistId,
                  title: thread.title || thread
                })}
                className={`${styles.chatButton} ${
                  currentChatId === id ? styles.chatButtonActive : ''
                }`}
                title={`${thread.title} (ID: ${id})`}
              >
                <Mic className={styles.chatIcon} />
                <div className={`${styles.chatContent} ${styles.hideWhenCollapsed}`}>
                  <div className={styles.chatTitle}>
                    <span>{thread.title}</span>
                  </div>
                </div>
                <button
                  onClick={(e) => handleDeleteChat(e, id)}
                  className={`${styles.deleteButton} ${styles.hideWhenCollapsed} ${
                    isDeleting === id ? styles.deleteButtonDisabled : ''
                  }`}
                  disabled={isDeleting === id}
                >
                  <Trash2 className={styles.deleteIcon} />
                </button>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatList;