import React from 'react';
import { palette } from './palette';
import { typography } from './typography';

export const cssVariables = {
  // Colors
  '--background-secondary': palette.background.secondary,
  '--border-primary': palette.border.primary,
  '--text-primary': palette.text.primary,
  '--messages-sent': palette.messages.sent,
  '--messages-sent-text': palette.messages.sentText,
  '--messages-received': palette.messages.received,
  '--messages-received-text': palette.messages.recievedText,
  '--accent-primary': palette.accent.primary,
  '--accent-hover': palette.accent.hover,
  '--accent-active': palette.accent.active,
  '--buttons-action': palette.buttons.actionDark,
  '--buttons-action-light': palette.buttons.actionLight,
  '--buttons-action-color-top': palette.buttons.actionColorTopGradient,
  '--buttons-action-color-bottom': palette.buttons.actionColorBottomGradient,
  
  // Typography
  '--font-primary': typography.fonts.primary,
  '--font-secondary': typography.fonts.secondary,
  '--font-mono': typography.fonts.mono,
  
  // Font Sizes
  '--font-size-xxs': typography.sizes.xxs,
  '--font-size-xs': typography.sizes.xs,
  '--font-size-sm': typography.sizes.sm,
  '--font-size-base': typography.sizes.base,
  '--font-size-lg': typography.sizes.lg,
  '--font-size-xl': typography.sizes.xl,
  '--font-size-2xl': typography.sizes['2xl'],
  '--font-size-3xl': typography.sizes['3xl'],
  '--font-size-4xl': typography.sizes['4xl'],
  
  // Font Weights
  '--font-weight-light': typography.weights.light,
  '--font-weight-normal': typography.weights.normal,
  '--font-weight-medium': typography.weights.medium,
  '--font-weight-semibold': typography.weights.semibold,
  '--font-weight-bold': typography.weights.bold,
  
  // Line Heights
  '--line-height-tight': typography.lineHeights.tight,
  '--line-height-normal': typography.lineHeights.normal,
  '--line-height-relaxed': typography.lineHeights.relaxed,
  
  // Letter Spacing
  '--letter-spacing-tight': typography.letterSpacing.tight,
  '--letter-spacing-normal': typography.letterSpacing.normal,
  '--letter-spacing-wide': typography.letterSpacing.wide,
} as React.CSSProperties;