import React, { useState, useEffect, useRef } from 'react';
import { API, API_BASE_URL, TOKEN } from './config';
import { Send } from 'lucide-react';
import styles from './styles/css/ChatPane.module.css';
import { cssVariables } from './styles/theme';

const TypingIndicator = () => (
  <div className={styles.typingIndicator}>
    <div className={styles.typingBubble}>
      <div className={styles.typingDots}>
        <div className={styles.typingDot}></div>
        <div className={styles.typingDot}></div>
        <div className={styles.typingDot}></div>
      </div>
    </div>
  </div>
);

const VerifiedBadge = () => (
  <svg className={styles.verifiedBadge} fill="currentColor" viewBox="0 0 24 24">
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
  </svg>
);

interface Message {
  content: string;
  timestamp: string;
  role: string;
  type: string;
}

const UserAvatar = ({ initials }: { initials: string }) => (
  <div className={styles.userAvatar}>{initials}</div>
);

const AssistantAvatar = () => (
  <div className={styles.assistantAvatar} />
);

const ChatPane = ({ currentChatId, artistInfo, userInitials = "JD" }: any) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [newMessage, setNewMessage] = useState('');
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    // @ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchMessages = async () => {
    if (!currentChatId) return;
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/${API.chat.listMessage}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ threadId: currentChatId }),
      });
      if (!response.ok) throw new Error('Failed to fetch messages');
      const data = await response.json();
      setMessages(data.messages || []);
      scrollToBottom();
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError('Failed to load messages');
    } finally {
      setIsLoading(false);
      setIsWaitingForResponse(false);
    }
  };

  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, 3000);
    return () => clearInterval(interval);
  }, [currentChatId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !currentChatId) return;
    
    setMessages(prev => [...prev, {
      content: newMessage,
      timestamp: new Date().toISOString(),
      role: 'user',
      type: 'message'
    }]);
    setNewMessage('');
    setIsWaitingForResponse(true);
    scrollToBottom();

    try {
      const response = await fetch(`${API_BASE_URL}/${API.chat.send}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          threadId: currentChatId,
          content: newMessage,
        }),
      });
      if (!response.ok) throw new Error('Failed to send message');
      await fetchMessages();
    } catch (err) {
      console.error('Error sending message:', err);
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <div className={styles.container} style={cssVariables}>
      <div className={styles.wrapper}>
        <div className={styles.chatContainer}>
          {artistInfo && (
            <div className={styles.artistHeader}>
              <div className={styles.artistInfo}>
                <img
                  src={artistInfo.imageUrl || "/api/placeholder/80/80"}
                  alt={artistInfo.name}
                  className={styles.artistAvatar}
                />
                <div className={styles.artistNameWrapper}>
                  <h1 className={styles.artistName}>{artistInfo.name}</h1>
                  <VerifiedBadge />
                </div>
                {artistInfo.role?.primary && (
                  <p className={styles.artistRole}>{artistInfo.role.primary}</p>
                )}
              </div>
            </div>
          )}

          <div className={styles.messagesArea}>
            <div className={styles.messagesList}>
              {isLoading && messages.length === 0 ? (
                <div className="text-center">Loading messages...</div>
              ) : error ? (
                <div className="text-center">{error}</div>
              ) : messages.length === 0 ? (
                <div className="text-center">No messages yet</div>
              ) : (
                <>
                  {messages.map((message, index) => (
                    <div key={`${message.timestamp}-${index}`} className={styles.messageWrapper}>
                      <div className={styles.messageContent}>
                        <div className={`${styles.messageBubble} ${
                          message.role === 'user' ? styles.sent : styles.received
                        }`}>
                          {message.role === 'user' ? (
                            <UserAvatar initials={userInitials} />
                          ) : (
                            <AssistantAvatar />
                          )}
                          <p className={styles.messageText}>{message.content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  {isWaitingForResponse && (
                    <div className={styles.messageWrapper}>
                      <div className={styles.messageContent}>
                        <div className={`${styles.messageBubble} ${styles.received}`}>
                          <AssistantAvatar />
                          <div className={styles.messageText}>
                            <TypingIndicator />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </>
              )}
            </div>
          </div>

          <div className={styles.inputSection}>
            <div className={styles.inputWrapper}>
              <form onSubmit={handleSubmit} className={styles.inputForm}>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Ask about music production..."
                    className={styles.textInput}
                  />
                  <div className={styles.assistantLabel}>
                    AI Music Assistant
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={!newMessage.trim()}
                  className={styles.sendButton}
                >
                  <span>Send</span>
                  <Send className="w-4 h-4" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPane;