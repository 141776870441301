export const typography = {
  fonts: {
    primary: 'Poppins, system-ui, sans-serif',
    secondary: 'DM Sans, Arial, sans-serif',
    mono: 'JetBrains Mono, monospace',
  },
  sizes: {
    xxs: '0.75rem',     
    xs: '0.80rem',      
    sm: '0.875rem',     
    base: '1rem',       
    lg: '1.125rem',     
    xl: '1.25rem',      
    '2xl': '1.5rem',    
    '3xl': '1.875rem',  
    '4xl': '2.25rem',   
  },
  weights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    tight: 1.2,
    normal: 1.5,
    relaxed: 1.75,
  },
  letterSpacing: {
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
  }
} as const;