export const palette = {
  background: {
    primary: "#fafafa",    // Main background (light blue-gray)
  secondary: "#f4f4f5",   // White sections
    tertiary: "#f8fafc",   // Slightly darker for alternate sections
    messageBox: "#f5f7fa"   // Very light blue for message input
  },

  accent: {
    primary: "#0284c7",    // Vibrant blue for primary actions
    hover: "#0369a1",      // Darker blue for hover
    active: "#e3e9f7"      // Lighter blue for active states
  },

  text: {
    primary: "#1e293b",    // Dark blue-gray for primary text
    secondary: "#475569",   // Medium blue-gray for secondary text
    muted: "#64748b",      // Light blue-gray for tertiary text
    inverse: "#ffffff"     // White text for dark backgrounds
  },

  border: {
    primary: "#e2e8f0",    // Main border color
    secondary: "#f1f5f9",  // Lighter border color
    focus: "#0284c7"       // Accent color for focus states
  },

  messages: {
    sent: "#f1e4e9",       // Blue for sent messages
    received: "#efe9f4",
    sentText: "",
    recievedText: ""
  },

  status: {
    online: "#22c55e",     // Green
    offline: "#64748b",    // Muted blue-gray
    verified: "#0284c7"    // Blue for verified badge
  },

  buttons: {
    actionDark: "#252931",
    actionLight: "#ececee",
    actionColorTopGradient: "#8a44c8",
    actionColorBottomGradient: "#df0c39",
    actionHover: "#333a45",
  }
};