import { useEffect, useState } from "react";
import { API, API_BASE_URL, TOKEN } from "./config";
import { ChevronRight, Play, Info } from "lucide-react";
import styles from './styles/css/RightSidebar.module.css';
import { cssVariables } from "./styles/theme";

interface ArtistDetails {
  _id: string;
  email: string;
  name: string;
  imageUrl: string;
  description: string;
  isActive: boolean;
  about: string;
  biography: string[];
  livesIn: string;
  musicalAchievements: string[];
  buisnessAchievements: string[];
  preferredGenres: string[];
  famousWorks: string[];
  socialMediaLinks: string[];
  role: {
    primary: string;
    secondary: string[];
  };
  lastLogin: string;
  createdAt: string;
  updatedAt: string;
}

const VerifiedBadge = () => (
  <svg className={styles.verifiedBadge} fill="currentColor" viewBox="0 0 24 24">
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
  </svg>
);

const RightSidebar = ({ artistId }: { artistId: string }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [artistDetails, setArtistDetails] = useState<ArtistDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState('profile');

  useEffect(() => {
    const fetchArtistDetails = async () => {
      if (!artistId) return;
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${API_BASE_URL}/${API.artist.info}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ artistId }),
        });
        if (!response.ok) throw new Error('Failed to fetch artist details');
        const data = await response.json();
        setArtistDetails(data);
      } catch (err) {
        console.error('Error fetching artist details:', err);
        setError('Failed to load artist details');
      } finally {
        setIsLoading(false);
      }
    };
    fetchArtistDetails();
  }, [artistId]);

  const renderProductionItem = (title: string, duration: string) => (
    <div className={styles.productionItem}>
      <button className={styles.productionPlayButton}>
        <Play className="w-4 h-4" />
      </button>
      <div className={styles.productionItemContent}>
        <span className={styles.productionItemTitle}>{title}</span>
        <span className={styles.productionItemDuration}>{duration}</span>
      </div>
    </div>
  );

  const renderProfileContent = () => (
    <div className={styles.content}>
      <div className={`${styles.contentSection} ${styles.hideWhenCollapsed}`}>
        <div className={styles.contentSection}>
          <h3 className={styles.sectionTitle}>Bio</h3>
          <p className={styles.sectionText}>{artistDetails?.about || "Renowned music producer with multiple platinum records..."}</p>
        </div>

        <div className={styles.contentSection}>
          <h3 className={styles.sectionTitle}>Genre</h3>
          <p className={styles.sectionText}>{artistDetails?.preferredGenres?.join(", ") || "Pop, Electronic, Hip-Hop"}</p>
        </div>

        <div className={styles.contentSection}>
          <h3 className={styles.sectionTitle}>Years Active</h3>
          <p className={styles.sectionText}>1995 - Present</p>
        </div>

        <div className={styles.contentSection}>
          <h3 className={styles.sectionTitle}>Production Samples</h3>
          <div className={styles.productionList}>
            {renderProductionItem("Pop Chorus Template", "0:30")}
            {renderProductionItem("EDM Drop Sample", "0:45")}
            {renderProductionItem("Hip-Hop Beat", "1:00")}
            {renderProductionItem("Vocal Stack Demo", "0:20")}
            {renderProductionItem("Synth Lead Pattern", "0:35")}
          </div>
        </div>

        <div className={styles.contentSection}>
          <h3 className={styles.sectionTitle}>Presets</h3>
          <div className={styles.productionList}>
            {renderProductionItem("Native Instruments 4 - Pop Lead", "Synth")}
            {renderProductionItem("Native Instruments 4 - EDM Bass", "Bass")}
            {renderProductionItem("Native Instruments 4 - Vocal Chain", "FX")}
            {renderProductionItem("Native Instruments 4 - Dance Drums", "Drums")}
            {renderProductionItem("Native Instruments 4 - Pad Ambient", "Pad")}
          </div>
        </div>
      </div>
    </div>
  );

  const renderArtifactsContent = () => (
    <div className={styles.content}>
      {/* Artifacts content here */}
    </div>
  );

  if (isLoading) {
    return (
      <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : styles.expanded}`} style={cssVariables}>
        <div className={styles.loadingState}>Loading artist details...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : styles.expanded}`} style={cssVariables}>
        <div className={styles.loadingState}>{error}</div>
      </div>
    );
  }

  if (!artistDetails) {
    return (
      <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : styles.expanded}`} style={cssVariables}>
        <div className={styles.loadingState}>Select a chat to view artist details</div>
      </div>
    );
  }

  return (
    <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : styles.expanded}`} style={cssVariables}>
      <div className={styles.header}>
        {isCollapsed ? (
          <Info className="w-6 h-6" />
        ) : (
          <h1 className={styles.title}>Artist Details</h1>
        )}
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className={styles.collapseButton}
        >
          {isCollapsed ? (
            <Info className="w-4 h-4" />
          ) : (
            <ChevronRight className="w-4 h-4" />
          )}
        </button>
      </div>

      <div className="h-full">
        {!isCollapsed && (
          <div className={styles.tabContainer}>
            <button
              className={`${styles.tab} ${activeTab === 'profile' ? styles.active : ''}`}
              onClick={() => setActiveTab('profile')}
            >
              Profile
            </button>
            <button
              className={`${styles.tab} ${activeTab === 'artifacts' ? styles.active : ''}`}
              onClick={() => setActiveTab('artifacts')}
            >
              Artifacts
            </button>
          </div>
        )}
        {(!isCollapsed && activeTab === 'profile') ? renderProfileContent() : renderArtifactsContent()}
      </div>
    </div>
  );
};

export default RightSidebar;