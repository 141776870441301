const API_BASE_URL = "http://127.0.0.1:3500/api/v1";
const TOKEN = "eyJhbGciOiJSUzI1NiIsImtpZCI6IlNLMmM1YzNQNXd6cnQwSGoza3JqcGxVaWIyV0FsIiwidHlwIjoiSldUIn0.eyJhbXIiOlsib2F1dGgiXSwiZHJuIjoiRFMiLCJlbWFpbCI6ImlkYW5Ac2Vzc2lvbjQyLmFpIiwiZXhwIjoxNzMyNzI3OTc0LCJpYXQiOjE3MzI3MjYxNzQsImlzcyI6IlAyYzVjM0pIdlo4dzVvaVJwTU1ablJLUnRQMmUiLCJsb2dpbklkcyI6WyJnb29nbGUtMTA0MjkxOTE5MzkyMjI5NzM4NjQ3IiwiaWRhbkBzZXNzaW9uNDIuYWkiXSwicmV4cCI6IjIwMjQtMTItMjVUMTY6NDk6MzRaIiwic3ViIjoiVTJreVR2M2NWYmNPVmU0aWpVY2lsR2cxdzc3TyIsInYyVXNlcklkIjoiNjczYjQ2YTc5NTIwYTQyNWFkOGE4MTU2In0.mDj_rlrHsO6qGzzduFjO5-mFAqM3IOOlj9435c1kvuhbQMww3zgEoaIPDYZOcDZzcUe_1YIIt57XJCLSjvcAucAH09XX_QNlCUtPvDmCVo7adHuMIc5qHQSq0w_fvgoivuiFHSqsDHyx4F6enWtlkJg5zKXTK3h4mwVOEHB-eto7kwG4ThAJnlgQcfkprCg6VQ6VPKVNtoBbzWxMRBfT_RqPlv8o9FKAXkQpQ2C6bWPn_N905AQcFNiWJ32p_JOKcqns7B2yc-rCXSzrsDL9Y0dxWJOdwiwhrCEO2_mTvW-9172USBVAOWnPtHKhta-U2e50yCNTzOPySdD2IFLhKw";
const API = {
    chat: {
        create: "chat/create",
        list: "chat/list",
        delete: "chat/delete",
        send: "chat/handle-message",
        listMessage: "chat/list-messages",
    },
    artist: {
        list: "artist/list",
        info: "artist/get"
    }
}

export {
    API_BASE_URL,
    TOKEN,
    API
};