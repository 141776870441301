import { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { API_BASE_URL, API, TOKEN } from './config';

const ArtistModal = ({ isOpen, onClose, onSelectArtist }) => {
  const [artists, setArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArtists = async () => {
      if (!isOpen) return; // Only fetch when modal is open
      
      setIsLoading(true);
      setError(null);
      
      try {
        const url = `${API_BASE_URL}/${API.artist.list}`;
        console.log(url);
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch artists');
        }

        const data = await response.json();
        const artists = data.artists;
        console.log('Fetched artists:', artists);
        setArtists(artists);
      } catch (err) {
        console.error('Error fetching artists:', err);
        setError('Failed to load artists. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchArtists();
  }, [isOpen]);

  if (!isOpen) return null;

  const handleArtistClick = async (artistId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/${API.chat.create}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${TOKEN}`,
        },
        body: JSON.stringify({ artistId }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to create chat');
      }

      const data = await response.json();
      console.log('Chat created:', data);
      onSelectArtist(data);
      onClose();
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        
        <h2 className="text-2xl font-semibold mb-4">Select an Artist</h2>
        
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {isLoading ? (
            <div className="col-span-full text-center py-8">
              Loading artists...
            </div>
          ) : error ? (
            <div className="col-span-full text-center py-8 text-red-500">
              {error}
            </div>
          ) : artists.length === 0 ? (
            <div className="col-span-full text-center py-8">
              No artists available
            </div>
          ) : (
            Object.entries(artists).map(([id, artist]) => (
              <button
                key={id}
                onClick={() => handleArtistClick(id)}
                className="bg-white border border-gray-200 rounded-lg p-4 hover:border-blue-500 transition-colors duration-200 flex flex-col items-center"
              >
                <img
                  src={artist.imageUrl}
                  alt={artist.name}
                  className="w-20 h-20 rounded-full mb-3"
                />
                <h3 className="font-medium text-gray-900">{artist.name}</h3>
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtistModal;