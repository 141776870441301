import { useState } from 'react';
import LeftSidebar from './LeftSidebar';
import ChatPane from './ChatPane';
import RightSidebar from './RightSidebar';
import { API, API_BASE_URL, TOKEN } from './config';
import "./styles/globals.css";

const App = () => {
  interface Chat {
    id: string;
    artistId?: string;
  }
  
  const [currentChat, setCurrentChat] = useState<Chat>();
  const [basicArtistInfo, setBasicArtistInfo] = useState(null);

  const fetchBasicArtistInfo = async (artistId: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/${API.artist.info}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ artistId }),
      });

      if (!response.ok) throw new Error('Failed to fetch artist info');

      const data = await response.json();
      console.log('Artist info:', data);
      setBasicArtistInfo(data);
    } catch (error) {
      console.error('Error fetching artist info:', error);
    }
  };

  const handleSelectChat = (chat: any) => {
    console.log('Selected chat:', chat);
    setCurrentChat(chat);
    if (chat?.artistId) {
      fetchBasicArtistInfo(chat.artistId);
    }
  };

  return (
    <div className="flex h-screen">
      <LeftSidebar 
        onSelectChat={handleSelectChat}
        currentChatId={currentChat?.id || ''}
      />
      <ChatPane 
        currentChatId={currentChat?.id}
        artistInfo={basicArtistInfo}
      />
      <RightSidebar 
        artistId={currentChat?.artistId || ''}
      />
    </div>
  );
};

export default App;